<template>
    <div class='not-found'>
       <p>404~</p>
       <p>页面走失····</p>
    </div>
</template>
<script lang='ts'>
import { reactive, toRefs } from 'vue'
export default {
    name: '',
    setup() {
         const state= reactive({
             value: 0
        })
        return {
            ...toRefs(state),
        }
    },
}
</script>
<style lang='scss' scoped>
.not-found {
    text-align: center;
    padding-top: 100px;
}
</style>